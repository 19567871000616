import { parseNumber } from '@brightspace-ui/intl/lib/number';
import * as filesize from 'filesize';
const regNumeric = /^\d[\d,.]*$/s;

//#region Number
const numFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});
const numFormatterOne = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
});
const numFormatterTwo = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});
const numFormatterThree = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
});
//#endregion
//#region Date
const shortDateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric', month: 'numeric', day: 'numeric'
});
const shortUTCDateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC'
});
const longDateFormatter = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium'
});
const longUTCDateFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC' //dateStyle: 'long'
});
const shortMonthFormatter = Intl.DateTimeFormat('en-US', {
    month: 'short'
});
const longMonthFormatter = Intl.DateTimeFormat('en-US', {
    month: 'long'
});
//#endregion
//#region Currency
const curFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});
const curFormatterCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});
const curFormatterShort = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
});
//#endregion
//#region Percent
const percFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0
});
const percFormatterOne = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
    /* @ts-expect-error roundingMode is newish */
    roundingMode: 'trunc'
});
const percFormatterTwo = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    /* @ts-expect-error roundingMode is newish */
    roundingMode: 'trunc'
});
const percFormatterThree = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 3,
    /* @ts-expect-error roundingMode is newish */
    roundingMode: 'trunc'
});
//#endregion
//#region FileSize
const size = filesize.partial({ round: 0});
const sizeOne = filesize.partial({ round: 1 });
const sizeTwo = filesize.partial({ round: 2 });
//#endregion

/**
 * Format a number as iec file size.
 * 
 * @param {number} value Number to be formated
 */
export function fileSize(value: number, decimals = 0, valueNaN = ''): string {
    if (isNaN(value))
        return valueNaN;
    switch (decimals) {
        case 1:
            return sizeOne(value) as string;
        case 2:
            return sizeTwo(value) as string;
        default:
            return size(value) as string;
    }
}

/**
 * 
 * Format a number as US formatted number with commas
 * @param {number} value Number to be formated
 */
export function number(value: number, decimals = 0, valueNaN = ''): string {
    if (isNaN(value))
        return valueNaN;
    switch (decimals) {
        case 1:
            return numFormatterOne.format(value);
        case 2:
            return numFormatterTwo.format(value);
        case 3:
            return numFormatterThree.format(value);
        default:
            return numFormatter.format(value);
    }
}

/**
 * 
 * Format a number as US formatted number as shorten format using K, M, B, etc...
 * @param {number} value Number to be formated
 */
export function shortNumber(value: number, valueNaN = ''): string {
    if (isNaN(value))
        return valueNaN;
    if (value >= 990_000 || value <= -990_000)
        return numFormatterTwo.format(value / 1_000_000) + 'M';
    if (value >= 10_000 || value <= -10_000)
        return numFormatter.format(value / 1_000) + 'K';
    if (value >= 1_000 || value <= -1_000)
        return numFormatterOne.format(Math.trunc(value / 100) / 10) + 'K';
    return numFormatter.format(value);
}

export type Duration = {
   years?: number
   months?: number
   weeks?: number
   days?: number
   hours?: number
   minutes?: number
   seconds?: number
   milliseconds?: number
   microseconds?: number
   nanoseconds?: number
};
export function formatDuration(duration: Duration) {
   let value = '';
   if (duration.years) {
      if (duration.years == 1)
         value += duration.years + ' year, ';
      else
         value += duration.years + ' years, ';
   }
   if (duration.months) {
      if (duration.months == 1)
         value += duration.months + ' month, ';
      else
         value += duration.months + ' months, ';
   }
   if (value.endsWith(', '))
      value = value.substring(0, value.length - 2);   
   return value;
   //return formatter.format(duration);
}

/**
 * Format a number as US currency as shorten format using K, M, B, etc...
 * @param {number} value Number to be formated
 * @param {boolean} includeCents Include cents in format
 */
export function shortCurrency(value: number, valueNaN = ''): string {
    if (isNaN(value))
        return valueNaN;
    if (value >= 990_000_000 || value <= -990_000_000)
        return curFormatterCents.format(value / 1_000_000_000) + 'B';
    if (value >= 990_000 || value <= -990_000)
        return curFormatterCents.format(value / 1_000_000) + 'M';
    if (value >= 100_000 || value <= -100_000)
        return curFormatter.format(value / 1_000) + 'K';
    if (value >= 10_000 || value <= -10_000)
        return curFormatterShort.format(value / 1_000) + 'K';
    //if (value >= 1_000 || value <= -1_000)
    //    return curFormatterShort.format(Math.trunc(value / 100) / 10) + 'K';
    return curFormatter.format(value);
}

/**
 * Format a date as US formatted date
 * @param {Date} date Date to be formated
 * @param {boolean} long Long date format
 * @deprecated Calling deprecated formatDate(date, long) function! Use date(date, long)
 */
export function formatDate(date: Date | number, long = false): string {
    console.warn('Calling deprecated formatters.formatDate() function! Use formatters.date()');
    if (long)
        return longDateFormatter.format(date);
    return shortDateFormatter.format(date);
}

/**
 * Format a date as US formatted date
 * @param {Date} date Date to be formated
 * @param {boolean} long Long date format
 */
export function date(date?: Date | number, long = false, nonDateValue = ''): string {
    if (!date || (Number.isFinite(date) && date == 0) || (date instanceof Date && date.getTime() == 0))
        return nonDateValue;
    if (long)
        return longDateFormatter.format(date);
    return shortDateFormatter.format(date);
}
export function month(date?: Date | number, long = false, nonDateValue = ''): string {
    if (!date || (Number.isFinite(date) && date == 0) || (date instanceof Date && date.getTime() == 0))
        return nonDateValue;
    if (typeof date == 'number')
        date = new Date(2024, date - 1);
    if (long)
        return longMonthFormatter.format(date);
    return shortMonthFormatter.format(date);
}

/**
 * Format a date as US formatted date
 * @param {Date} date Date to be formated
 * @param {boolean} long Long date format
 */
export function dateUTC(date?: Date | number, long = false, nonDateValue = ''): string {
    if (!date || (Number.isFinite(date) && date == 0) || (date instanceof Date && date.getTime() == 0))
        return nonDateValue;
    if (long)
        return longUTCDateFormatter.format(date);
    return shortUTCDateFormatter.format(date);
}

/**
 * Format a number as US currency
 * @param {number} value Number to be formated
 * @param {boolean} includeCents Include cents in format
 */
export function currency(value: number, includeCents = false, valueNaN = '', zeroAsNaN = false): string {
    if (isNaN(value) || (value == 0 && zeroAsNaN))
        return valueNaN;
    if (includeCents)
        return curFormatterCents.format(value);
    return curFormatter.format(value);
}

/**
 * Format number as percent using US formating using commas
 * @param {number} value
 * @param {number} decimals
 */
export function percent(value?: number, decimals = 0, valueNaN = ''): string {
    if (isNaN(value))
        return valueNaN;
    switch (decimals) {
        case 1:
            return percFormatterOne.format(value);
        case 2:
            return percFormatterTwo.format(value);
        case 3:
            return percFormatterThree.format(value);
        default:
            return percFormatter.format(value);
    }
}

/**
 * Parse string into number using US formating using commas
 * @param {string} value
 */
export function parse(value: string): number {
    return parseNumber(value);
}

/**
 * Test string if valid numric number with commas or periods
 * @param {string} value
 */
export function test(value: string): boolean {
    return regNumeric.test(value);
}
